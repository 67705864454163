.DropDownModalMain,
.simpleDropDownModalMain,
.DropDownModalTwoMain,
.modalsMain,
.FourComponentModalMain,
.ModalsTwoMain,
.FullScreenModalMain {
    position: relative;
}

.DropDownModal,
.simpleDropDownModal,
.simpleDropDownModalTwo,
.DropDownModalChild,
.DropDownModalChildOne,
.DropDownModalTwo,
.modal,
.FourComponentModal,
.Page,
.ModalsTwoUserAccount,
.ModalsTwoVendorAccount,
.Sale,
.Vendor,
.Shop,
.Orders,
.Profiles,
.Address,
.Support,
.Products,
.Order,
.ModalsTwoPage {
    overflow: auto;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4);
    background-color: #FFFFFF;
    z-index: 1;
    border-radius: 0 0 5px 5px;
}

.DropDownModal {
    position: absolute;
    margin-top: 11px;
    width: 57rem;
    left: 100%;
    margin-left: 15px;
}


.simpleDropDownModal {
    position: absolute;
    margin-top: 11px;
    left: 100%;
    width: 100%;
    margin-left: 15px;
}

.simpleDropDownModalTwo {
    position: absolute;
    margin-top: 11px;
    left: 257px;
    width: 18.5rem;
    margin-left: 15px;
}

.DropDownModalChild {
    position: absolute;
    left: 42rem;
    width: 200%;
    top: 10px;
}

.DropDownModalChildOne {
    position: absolute;
    left: 39rem;
    top: 10px;
    width: 41.8rem;
    border-radius: 10px;
}

.DropDownModalTwo {
    position: absolute;
    margin-top: 11px;
    left: 272px;
    width: 54rem;
    border-radius: 10px;
}



.modal {
    position: absolute;
    top: 20px;
    right: 82%;
    width: 25%;

}

.FourComponentModal {
    display: none;
    position: absolute;
    top: 20px;
    right: 24%;
    width: 65rem;
}

.ModalsTwoPage {
    position: absolute;
    top: 20px;
    right: 29%;
    width: 29%;

}

.ModalsTwoUserAccount {
    position: absolute;
    top: 20px;
    right: 16%;
    width: 29%;

}

.ModalsTwoVendorAccount {
    position: absolute;
    top: 20px;
    left: 76%;
    width: 29%;
}

.Sale {
    position: absolute;
    left: 47%;
    top: 190px;
    width: 16%;

}

.Vendor {
    position: absolute;
    left: 47%;
    top: 230px;
    width: 16%;

}

.Shop {
    position: absolute;
    left: 47%;
    top: 270px;
    width: 16%;

}

.Orders {
    position: absolute;
    left: 54.222%;
    top: 190px;
    width: 16%;
}

.Profiles {
    position: absolute;
    left: 54.222%;
    top: 230px;
    width: 16%;

}

.Address {
    position: absolute;
    left: 54.222%;
    top: 270px;
    width: 16%;

}

.Support {
    position: absolute;
    left: 54.222%;
    top: 310px;
    width: 16%;

}

.Products {
    position: absolute;
    left: 65.55%;
    top: 230px;
    width: 16%;
}

.Order {
    position: absolute;
    left: 65.555%;
    top: 270px;
    width: 16%;
}



.fullScreenLeft {
    position: absolute;
    left: -36.2rem;
    margin-top: 16px;
    width: 35%;
    border-right: 1px solid #ccc;
    height: 37.1rem;
    overflow: auto;
    z-index: 98;
    background-color: #FFFFFF;
    border-bottom: 1px solid #ccc;
}

.fullScreenRight {
    position: absolute;
    margin-top: 16px;
    left: -19.2rem;
    height: 37.1rem;
    overflow: auto;
    z-index: 98;
    background-color: #FFFFFF;
    width: 1067px;
    padding: 46px 0;
    padding-left: 28px;
    border-bottom: 1px solid #ccc;
}

.FullScreenModalList ul {
    margin: 5px 0;
}

.FullScreenModalList ul>li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    font-size: 17px;
}

.FullScreenModalList ul>li:hover {
    background-color: #FCE9EC;
    color: #D23F57;

}

.FullScreenDataFirst>ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.FullScreenDataFirst ul>li {
    list-style: none;
    width: 20%;
    padding: 10px 0;
}

.FullScreenDataFirst ul>li:hover {

    color: #D23F57;
}

.FullScreenDataFirst {
    padding-bottom: 35px;
}

.FourComponentModalUnderMain {
    display: flex;
}

.FourComponentModalUnderTxt {
    width: 25%;
}

.FourComponentModalDivColor {
    background-color: #F6F9FC;

}

.FourComponentModalUnderTxt ul>li {
    color: #000000;
    padding: 7px 0 7px 24px;
    list-style: none;
}


.FourComponentModalUnderTxt ul {
    margin: 20px 0;
}

.FourComponentModalUnderTxt ul li:first-child {
    color: #000000 !important;
    background: none;
    font-weight: 700;
}

.FourComponentModalUnderTxt ul li:first-child:hover {
    color: #000000 !important;
    background: none;
    font-weight: 700;
}


.FourComponentModalUnderTxt ul>li:hover {
    color: #D23F57;
    background-color: #f5f5f5;
}

.modalUnderTxtMain {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
}

.modaldiv {
    display: flex;
}

.modaldiv2 {
    display: block;
}

.modalLeftSide {
    width: 47vw;
}

.modalLeftSide2 {
    /* width: 58vw !important; */
}

.modalPara {
    padding: 8px 0;
}

.modalheightSet {
    display: flex;
    flex-direction: column;

}

.DropDownModalHeight {
    display: flex;
    flex-direction: column;
    min-height: 206px;
    justify-content: space-evenly;
}

.modalHeader {
    display: flex;
    justify-content: space-around;
    width: 90%;

}

.headerTxt {
    font-weight: 700;
}

.headerTxt:hover {
    color: #D23F57;
}

.modalPara:hover {
    color: #D23F57;
}

.modalRightSide {
    padding-top: 22px;
}

.modalRightSide2 {
    padding-top: 0;
}

.modalRightSide2>img {
    width: 100%;
}

.modalParaMain>p:first-child {
    font-weight: 700;
}

.simpleDropDownModal ul>li,
.simpleDropDownModalTwo ul>li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000000;
    padding: 10px;
    text-decoration: none;
}

.modal ul>li {
    padding: 8px;
    color: #000000;
    text-decoration: none;
}

.modal ul {
    margin: 6px 0;
}

.modal ul>li:hover {
    color: #D23F57;
    background-color: #f5f5f5;
}

.simpleDropDownModal ul>li:hover,
.simpleDropDownModalTwo ul>li:hover {
    color: #D23F57;
    background-color: #f5f5f5;
}

.DropDownModalTwoRight {
    display: flex;
}

.DropDownModalTwoRightTxt {
    padding-left: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#DropDownModalTwoRightHead {
    font-size: 24px;
    font-weight: 700;
}

#DropDownModalTwoRightPara1 {
    font-size: 15px;
}

#DropDownModalTwoRightPara2 {
    font-weight: 750;
    border-bottom: 2px solid #D23F57;
    width: 30%;
}

.DropDownModalTwoRightImg {
    width: 50%;
}

.DropDownModalTwoRightImg>img {
    width: 100%;
}

.liActive {
    background-color: #f5f5f5;
    color: #D23F57 !important;
}

.noActive {
    background-color: #FFFFFF;
    color: #000000;
}

.ModalsTwoList ul>li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000000;
    padding: 10px;
    text-decoration: none;
}

.ModalsTwoList ul>li:hover {
    color: #D23F57;
    background-color: #f5f5f5;

}

.ModalsTwoList {
    margin: 8px 0;
}

.ModalsTowMainUnder ul>li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000000;
    padding: 10px;
    text-decoration: none;
}

.ModalsTowMainUnder ul>li:hover {
    color: #D23F57;
    background-color: #f5f5f5;
}



.FullScreenModalListTwo ul>li {
    list-style: none;
    font-size: 14px;
    width: 19%;
    padding-bottom: 10px;

}

.FullScreenModalListTwo ul {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 4rem;
}

.FullScreenModalListTwo ul>li:hover {
    color: #D23F57;
}

.FullScreenModalListTwoHeader {
    padding-bottom: 20px;
    font-weight: 700;
}

/* CardModal */
.CardModalFirst {
    background-color: black;
    opacity: 0.5;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.CardModalSecond {
    position: fixed;
    z-index: 3;
    top: 0;
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    /* width: 77vw; */
    width: 100%;

}

.CardModalBody {

    position: relative;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    max-height: calc(100% - 64px);
    max-width: calc(100% - 64px);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
    flex: 1 1 auto;
    overflow-y: auto;
    padding-bottom: 20px;
    /* max-width: 900px; */
    width: 100%;

}

.CardModalSecondImg {
    width: 100%;
    padding-top: 24px;
    padding-left: 24px;
}

.CardModalSecondImg>img {
    width: 100%;
}

.CardModalSecondBody {
    padding-top: 24px;
    padding-left: 24px;
    font-family: Open sans;
}

.CardModalSecondStar {
    display: flex;
    gap: 10px;
}

.CardModalSecondBody hr {
    margin: 0px 0px 16px;
    flex-shrink: 0;
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: rgb(243, 245, 249);
}

.CardModalSecondBody h1 {
    font-size: 30px;
    font-weight: 700;
    color: #D23F57;
}

.CardModalSecondBody h2 {
    font-size: 25px;
    font-weight: 700;
}

.AddCartBtn {
    padding: 9.6px 40px;
    color: rgb(255, 255, 255);
    background-color: rgb(210, 63, 87);
    border: 1px solid rgb(210, 63, 87);
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;

}

.AddCartBtn:hover {
    background-color: #e3364e;
}

.CardModalSecondBody>p:nth-child(2) {
    padding: 8px 0;
    color: rgb(174, 180, 190);
    font-size: 13px;
}

.CardModalSecondBody>p:nth-child(5) {
    padding: 16px 0;
}

.closeIcon {
    position: absolute;
    right: 10px;
    top: 8px;
}

#CloseIcon {
    font-size: 20px;
    cursor: pointer;
}

.AddCart>button {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: "Open Sans", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 0.8125rem;
    line-height: 1.75;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid rgba(210, 63, 87, 0.5);
    color: rgb(210, 63, 87);
    min-width: 0px;
    min-height: 0px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 0.6rem;
    height: 45px;
}

.AddCart span {
    margin: 0px 20px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    text-transform: none;
    white-space: normal;
}

/* CardModal */