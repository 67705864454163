.sidebarMain {
    background-color: black;
    opacity: 0.5;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.sidebar {
    height: 100%;
    position: fixed;
    overflow: hidden;
    right: 0;
    top: 0;
    background-color: white;
    z-index: 3;
    transition: 0.2s ease-out;
    transform-origin: right;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#sidebarWidthTwo {
    width: 360px !important;
}


.fullScreenSideBarMain {
    height: 100%;
    position: fixed;
    overflow: hidden;
    left: 0;
    top: 0;
    background-color: white;
    z-index: 3;
    transition: 0.2s ease-out;
    transform-origin: left;
    width: 100%;
}

.fullScreen {

    overflow-y: scroll;
}

.fullScreenSideBarData {

    margin: auto;
    position: relative;
    height: 100vh;
    padding: 64px 40px;
    max-width: 500px;
}

.fullScreenSideBarName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    padding: 13px 0;
}
.fullScreenSideBarName>span:nth-child(1){
    font-weight: 600;
} 

.fullScreenSideBarChild {
    width: 50%;
    margin: auto;


}

.fullScreenSideBarCloseIcon {
    position: absolute;
    right: 40px;
    top: 30px;
}

.emptyItem {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.emptyItemTxt {
    padding-top: 20px;
    font-size: 14px;
    color: rgb(125, 135, 156);
}

#headerItemCount {
    font-weight: 600;
    color: rgb(15, 52, 96);
}

.sliderButton {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 25px 20px;
    align-items: center;
    border-bottom: 1px solid rgb(243, 245, 249);
}

.sliderButton>span {
    display: flex;
    align-items: center;
    gap: 8px;
}

#sidebarShopIcon {
    font-size: 30px;
    text-align: center;
}

#sidebarCloseIcon {
    font-size: 20px;
}

.sidebarShopData {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 18px 0;
    border-bottom: 1px solid rgb(243, 245, 249);
}

.sidebarButtons {
    position: sticky;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: end;
    z-index: 1;
    background-color: #FFF;
}

.sidebarDataAndBtn {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
}

.sidebarButtons>button {
    padding: 6px 16px;
    margin: 8px 16px;
    width: 91%;
}

.sidebarButtonOne {
    border: 1px solid rgb(210, 63, 87);
    color: rgb(255, 255, 255);
    background-color: rgb(210, 63, 87);
    border: none;
}

.sidebarButtonTwo {
    border: 1px solid rgb(210, 63, 87);
    background-color: #FFF;
}

#sidebarShopImg {
    width: 22%;
}

#sidebarShopImg>img {
    width: 100%;
    height: 100%;
}

#sidebarShopTxts {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#sidebarShopPlusIcon {
    border: 1px solid rgba(210, 63, 87, 0.5);
    padding: 8px 8px;
    border-radius: 56px;
    display: flex;

    color: rgb(210, 63, 87);
}

#sidebarShopPlusIcon:active {
    background-color: rgba(210, 63, 87, 0.4);
}

#sideShopTxtTitle {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 130px;
    white-space: nowrap;
    font-weight: 700;
    text-transform: capitalize;
}

#sideShopTxt {
    font-size: 10px;
    color: rgb(125, 135, 156)
}

#sideShopTxtAmmount {
    color: rgb(210, 63, 87);
    font-weight: 700;
    font-size: 14px;
}

#minus {
    border: 1px solid #ccc !important;
    color: #ccc !important;
    padding: 8px 8px;
    border-radius: 56px;
    display: flex;
}

#minus:active {
    background-color: #FFF !important;
    color: #ccc;
}

.fullScreenData {
    margin-left: 16px;
    margin-right: 16px;

}

.fullScreenData>p {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
}

.FullScreenSidebarTwo {
    margin-left: 14px;
    margin-right: 14px;

}

.FullScreenSidebarTwo>p {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
}