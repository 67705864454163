/* CardButtons css */
.cardButton {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50%;
    border-radius: 9px;
    background-color: #fff;
    box-shadow: 0px 1px 3px rgb(3 0 71 / 9%);
    width: 58%;
    padding: 1.25rem;
    min-width: 240px;
}

.cardBtn1 {
    display: flex;
    flex-direction: column;
}

#widthButtondSec1 {
    width: 85%;
    min-width: 240px;
}

#widthButtondSec2 {
    width: 90%;
    min-width: 240px;

}

.cardBtn1>Button {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: 600;
    padding: 0.75rem 1rem;
    margin-bottom: 0.75rem;
    background-color: #F6F9FC;
    color: black;
    border: none;

}


.cardBtn2>Button {
    font-weight: 600;
    margin-top: 64px;
    width: 100%;
    padding: 0.75rem 1rem;
    background-color: #F6F9FC;
    color: black;
    border: none;
}


.cardHeaderTxt {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 15px;
}

.cardHeaderTxt>h3 {
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}

.cardSlash {
    color: silver;
}


.cardBtn1>Button:hover {
    box-shadow: 0px 8px 45px rgb(3 0 71 / 9%);
}

.cardBtn2>Button:hover {
    box-shadow: 0px 8px 45px rgb(3 0 71 / 9%);
}



/* CardButtons css */