@import url('https://fonts.googleapis.com/css2?family=Aboreto&family=Alexandria:wght@400;900&family=Anek+Latin:wght@100;300&family=IBM+Plex+Mono:wght@200&family=Inconsolata:wght@200;300;400&family=Montserrat:wght@300&family=Open+Sans:ital,wght@0,300;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=PT+Serif:ital@0;1&family=Poppins:wght@200;300;400&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;1,100&family=Roboto:ital,wght@0,300;1,100&family=Source+Sans+Pro:wght@600&display=swap');



.IconTxtHeader {
    font-family: Open sans;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}


.IconTxts {
    display: flex;
    align-items: center;
    gap: 10px;
}

.IconTxt {
    font-size: 24px;
    font-weight: 700;
}

.TxtHeaderIcon {
    display: flex;
    align-items: center;
}

.TxtHeaderIconUnder {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #7D879C;
    cursor: pointer;
}