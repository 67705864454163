@import url('https://fonts.googleapis.com/css2?family=Aboreto&family=Alexandria:wght@400;900&family=Anek+Latin:wght@100;300&family=IBM+Plex+Mono:wght@200&family=Inconsolata:wght@200;300;400&family=Montserrat:wght@300&family=Open+Sans:ital,wght@0,300;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=PT+Serif:ital@0;1&family=Poppins:wght@200;300;400&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;1,100&family=Roboto:ital,wght@0,300;1,100&family=Source+Sans+Pro:wght@600&display=swap');

.carousel {
    overflow: hidden;
    cursor: pointer;
    padding-top: 32px;
    padding-bottom: 32px;
    overflow: hidden;
}

.inner,
.cardInner {
    white-space: nowrap;
    transition: 0.6s;

}

.SecondSliderMain {
    overflow: hidden;
    display: flex;
}



.fristSlider {
    display: flex;
    justify-content: space-around;
    width: 89%;
}

.sliderTxt {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 3px;
    max-width: 500px;
}

.sliderTxt>h1 {
    font-family: Open Sans;
    font-size: 51px;
    margin-bottom: 21px;
    color: #2B3445;
    white-space: initial;
    font-weight: 700;
    line-height: 60px;
    word-spacing: -3px;
}

.firstPersonSlider {
    display: flex;
}

.sliderTxt>p {
    font-family: Open sans;
    margin-bottom: 21px;
    color: #0F347D;
    white-space: initial;
    font-size: 14px;
}

.firstBtn>Button {
    padding: 10px 40px;
    color: #fff;
    background-color: #D23F57;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 700;
    font-size: 15px;
}

.firstBtn>Button:active {
    background-color: rgba(210, 63, 87, 0.8);
    color: white;
}

.sliderImg>img {
    max-height: 400px;
    display: block;
    max-width: 100%;
    padding-right: 35px;
}


.carousel-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
}

.indicator {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.indicator>button {
    margin: 10px;
}

.indicator>input[type="radio"] {
    accent-color: #0F3460;
    width: 16px;
    height: 16px;
    margin: 4px;
}

#thirdSliderWidth {
    width: 168%;
}

.thirdSliderMain,
.fourSliderMain {
    overflow: hidden;
}

/* resposive */